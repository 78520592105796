@import url('https://fonts.googleapis.com/css?family=Inconsolata|Lato:300,400,700');

html, body, h1, h2, h3, h4, h5, h6, p, li, ol, ul, pre {
	margin: 0;
	padding: 0;
}

#header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 70px; 
	display: flex;
	align-items: center; 
	z-index: 2;
	background-color: #fff;
}
  
.links {
	background-color: rgb(0, 0, 0);
	background-image: linear-gradient(to bottom, rgba(159, 159, 159, 0.358), transparent);
	border-bottom: 1px solid #0003;
	box-shadow: 0 0 32px #0003;
	font-size: 2em;
	font-weight: 300;
	width: 100%;
	display: flex; 
	justify-content: space-around; 
	align-items: center;
	height: 100%; 
}


.links #home {
	font-size: 40px;
}



.links > a {
	color: rgb(255, 255, 255);
	padding: .75em;
	text-align: center;
	text-decoration: none;
	transition: all .5s;
	font-size: 20px;
}

.links > a:hover {
	background: #ffffff1a;
	color: rgb(255, 255, 255);
	padding: 10px;
}


/* cards / schools */

:root {
	--card-height: 40vw;
	--card-margin: 4vw;
	--card-top-offset: 1em;
	--numcards: 4;
	--outline-width: 0px;
}
#cards {
	padding-bottom: calc(var(--numcards) * var(--card-top-offset)); 
	margin-bottom: var(--card-margin);
	z-index: 1;
}

#card_1 {
	--index: 1;
}

#card_2 {
	--index: 2;
}

#card_3 {
	--index: 3;
}

#card_4 {
--index: 4;
}

.card {
	position: sticky;
	top: 100px;
	padding-top: calc(var(--index) * var(--card-top-offset));
}

@supports (animation-timeline: works) {

@scroll-timeline cards-element-scrolls-in-body {
	source: selector(body);
	scroll-offsets:
	
		selector(#cards) start 1,
		
		selector(#cards) start 0
	;
	start: selector(#cards) start 1; 
	end: selector(#cards) start 0; 
	time-range: 4s;
}

.card {
	--index0: calc(var(--index) - 1); 
	--reverse-index: calc(var(--numcards) - var(--index0));
	--reverse-index0: calc(var(--reverse-index) - 1); 
	
}

.card__content {
	transform-origin: 50% 0%;
	will-change: transform;

	--duration: calc(var(--reverse-index0) * 1s);
	--delay: calc(var(--index0) * 1s);

	animation: var(--duration) linear scale var(--delay) forwards;
	animation-timeline: cards-element-scrolls-in-body;
}

@media (max-width: 768px) {
	/* Header styles for smaller screens */
	#header {
		height: auto;
		padding: 10px 0;
		text-align: center;
		position: relative;
		
	}
	.links {
		flex-direction: column;
		height: auto;
		padding: 20px 0;
		width: fit-content;
	}
	.links > a {
		font-size: 16px;
		margin: 10px 0;
	}
	/* Other styles for smaller screens go here */
	#about {
		margin: 70px auto;
	}
	#about h1 {
		font-size: 2rem;
	}
	#about p {
		font-size: 1rem;
		margin-bottom: 1.5rem;
	}
	/* Add more responsive styles as needed */
}


@keyframes scale {
	to {
		transform:
			scale(calc(
				1.1
				-
				calc(0.1 * var(--reverse-index))
			));
	}
}
}

main {
	width: 80vw;
	margin: 0 auto;

}

#cards {
	list-style: none;
	outline: calc(var(--outline-width) * 10) solid blue;

	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(var(--numcards), var(--card-height));
	gap: var(--card-margin);
	

}

.card {
	outline: var(--outline-width) solid hotpink;


}

.card__content {
	box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
	background: rgb(255, 255, 255);
	color: rgb(10, 5, 7);
	border-radius: 1em;
	overflow: hidden;

	display: grid;
	grid-template-areas: "text img";
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;

	align-items: stretch;
	outline: var(--outline-width) solid lime;

}

.card__content > div {
	grid-area: text;
	width: 80%;
	place-self: center;
	text-align: left;

	display: grid;
	gap: 1em;
	place-items: start;
	}

	.card__content > figure {
	grid-area: img;
	overflow: hidden;
}

.card__content > figure > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.card h1 {
	font-weight: 300;
	font-size: 3.5em;
}

.card h2 {
	font-weight: 300;
	font-size: 2.5em;
}

.card p {
	font-style: italic;
	font-weight: 300;
	line-height: 1.42;
	font-size: 20px;
}

.card .btn {
	background: #0478F6;
	cursor: pointer;
	color: rgb(255 255 255);
	text-decoration: none;
	display: inline-block;
	padding: 0.5em;
	border-radius: 0.25em;
	/* cursor: pointer; */
}


#about {
	width: 100%;
	margin: 150px auto;
	text-align: center;
	background-color: #f4f8f9;
	
  }
  
#about h1 {
	font-size: 2.5rem;
	margin-bottom: 2rem;
  }
  
#about p {
	font-size: 1.2rem;
	line-height: 1.5;
	margin-bottom: 2rem;  
}
  


.slideshow-container {
	position: relative;
	width: 100%;
	height: 600px;
	overflow: hidden;
	margin-top: 70px;
  }
  
  .slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 1s ease-in-out;
  }
  
  .slide.active {
	opacity: 1;
  }
  
  .slide img {
	object-fit: cover;
	width: 100%;
	height: 100%;
  }
  
  .caption {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 18px;
  }
  
  .dots {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
  }
  
  .dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #bbb;
	margin-right: 10px;
	cursor: pointer;
  }
  
  .dot.active {
	background-color: #fff;
  }


.dialog-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6); 
	display: none; 
	z-index: 999; 
  }
  
 
  .dialog-container.show {
	display: flex;
	align-items: center;
	justify-content: center;
	animation: fade-in 0.3s ease-in-out;
  }
  
 
  .dialog-container.hide {
	animation: fade-out 0.3s ease-in-out;
  }

  .dialog {
	position: relative;
	background-color: #fff;
	width: 400px;
	padding: 20px;
	border-radius: 10px;
  }
  

  .close-btn {
	position: absolute;
	top: 5px;
	right: 5px;
	background-color: transparent;
	border: none;
	cursor: pointer;
  }
  
  
  @keyframes fade-in {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  
  @keyframes fade-out {
	from {
	  opacity: 1;
	}
	to {
	  opacity: 0;
	}
  }



#steps  {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background: #1A1C21;
	min-height: 100vh;
	
}

#stepsOuter {
	position: relative;
  }
  
#stepsOuter h1 {
	text-align: center;
	position: absolute;
	left: 50%;
	top: 10%;
	transform: translate(-50%, -50%);
	color: #fff;
	font-size: 50px;
}

#steps section::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(#4e4e4e, #ffffff);
	clip-path: circle(30% at right 70%);
}

#steps section::after {
	content: '';
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(#dadada, #ffffff);
	clip-path: circle(20% at 10% 10%);
	
}

#steps .container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 300px;
	
}

#steps .container .card {
	position: relative;
	width: 300px;
	height: 400px;
	background: rgba(255, 255, 255, 0.05);
	margin: 20px;
	box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;

	backdrop-filter: blur(10px);
}

#steps .container .card .content {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: 0.5s;

}

#steps .container .card:hover .content {
	transform: translateY(-20px);
	
}

#steps .container .card .content .imgBx {
	position: relative;
	width: 150px;
	height: 150px;
	overflow: hidden;
}

#steps .container .card .content .imgBx img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#steps .container .card .content .contentBx h3 {
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 500;
	font-size: 18px;
	text-align: center;
	margin: 20px 0 10px;
	line-height: 1.1em;
}

#steps .container .card .content .contentBx h3 span {
	font-size: 12px;
	font-weight: 300;
	text-transform: initial;
}

#steps .container .card .sci {
	position: absolute;
	bottom: 50px;
	display: flex;
}

#steps .container .card .sci li {
	list-style: none;
	margin: 0 10px;
	transform: translateY(40px);
	transition: 0.5s;
	opacity: 0;
}

#steps .container .card:hover .sci li {
	transform: translateY(0px);
	opacity: 1;
}

#steps .container .card .sci li a {
	color: #fff;
	font-size: 20px;
}

#steps h3 {
	font-size: 209px;
}

.stepNumbers {
	font-size: 100px !important;
}

#steps p {
	position: absolute;
	color: #ffffff;
	bottom: 150px;
	font-size: 20px;
	font-family:'Times New Roman', Times, serif
}

.des {
	font-size: 50px;
	font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}



.center button {
	margin: auto;
	border-radius: 25px;
	background-color: #33A6FF;
	box-shadow: 1px 15px 15px #3071ba;
	width: 250px;
	height: 50px;
	font-size: 30px;
	font-family: cambria;
	color: #ffffff;
	border: none;
  }
  
  .center button:hover {
	background-color: #33A6FF;
	opacity: 1;
	color: #ffffff;
  }
  .center button:hover {
	transform: scale(1.1);
	cursor: pointer;
  }
  
  .center .btn:hover {
	transition: 0.5s ease-in-out;
	background: #33a6ff;
  }
  
  .center {
	position: absolute;
	bottom: 30px;
	
  }
  
  .center .btn:hover {
	transition: 0.5s ease-in-out;
	background: #272728;
	cursor: pointer;
  }
  
  .center #button {
	--color1: #ffffff;
	--color2: #bccad6;
	font-family: cambria;
	text-align: center;
	font-size: 46px;
	background: repeating-linear-gradient(
		45deg,
		var(--color1),
		var(--color1) 30px,
		var(--color2) 30px,
		var(--color2) 60px
	);
	background-clip: text;
	color: transparent;
	-webkit-background-clip: text;
	animation: 40s linear 0s infinite move;
  }
  
  @keyframes move {
	from {
	  background-position: 0px;
	}
  
	to {
	  background-position: 1000px;
	}
  }
  


#contact {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: linear-gradient(to bottom right, #1A1C21, #1A1C21);
  }
  
  #contact h1 {
	font-size: 4rem;
	color: #fff;
	text-align: center;
	text-shadow: 2px 2px #333;
	margin-bottom: 3rem;
  }
  
  #contact p {
	font-size: 1.5rem;
	color: #fff;
	text-align: center;
	text-shadow: 1px 1px #333;
  }
  
  #contact button {
	font-size: 1.3rem;
	color: #fff;
	background: #33a6ff;
	padding: 1rem 2rem;
	border-radius: 1rem;
	border: none;
	margin-top: 2rem;
	cursor: pointer;
	box-shadow: 2px 2px #33a6ff;
  }
  



.faq {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	font-family: Arial, sans-serif;
  }
  
  .faq h2 {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 20px;
  }
  
  .faq .accordion-item {
	margin-bottom: 20px;
	
  }
  
  .faq .accordion-header {
	padding: 10px;
	background-color: #f2f2f2;
	border: 1px solid #ccc;
	cursor: pointer;
	color: #000000
  }
  
  .faq .accordion-header:hover {
	background-color: #e6e6e6;
  }
  
  .faq .accordion-header.active {
	background-color: #007bff;
	color: #fff;
  }
  
  .faq .accordion-content {
	padding: 10px;
	background-color: #fff;
	border: 1px solid #ccc;
  }
  
  .faq .accordion-content.active {
	display: block;
  }

  .faq {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	font-family: Arial, sans-serif;
	}
	
	.faq h2 {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 20px;
	text-align: center;
	}
	
	.accordion-item {
	border: 1px solid #e8e8e8;
	border-radius: 5px;
	margin-bottom: 10px;
	overflow: hidden;
	}
	
	.accordion-header {
	background-color: #f2f2f2;
	padding: 15px;
	font-size: 18px;
	font-weight: bold;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	}
	
	.hint {
	font-size: 12px;
	color: #666666;
	margin-left: 10px;
	}
	
	.arrow {
	margin-left: auto;
	font-size: 20px;
	transition: transform 0.3s ease;
	}
	
	.accordion-header:hover .arrow {
	transform: rotateX(180deg);
	}
	
	.accordion-content {
	background-color: #ffffff;
	padding: 15px;
	font-size: 16px;
	line-height: 1.6;
	display: none;
	}
	
	.accordion-content.active {
	display: block;
	}
	
	.accordion-header.active {
	background-color: #007bff;
	color: #ffffff;
	}


.school-container {
	max-width: auto;
	margin: 0 auto;
	
  }
  

  .banner {
	animation: fadeIn 1s ease-in-out;
	color: #fff;
	text-align: center;
	padding: 12rem;
	position: absolute; 
	top: 70px; 
	left: 0;
	right: 0;
	z-index: 1;
  }
  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  @keyframes slideIn {
	from {
	  transform: translateX(-100%);
	}
	to {
	  transform: translateX(0);
	}
  }
  .banner::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: -1; 
  }
  
 
  .banner-title {
	font-size: 60px;
	font-weight: bold;
	margin-bottom: 1rem;
	z-index: 1; 
  }
  
  .banner-description {
	font-size: 20px;
	margin-bottom: 1rem;
	z-index: 1; 
  }

  .banner-title,
.banner-description {
  animation: slideIn 1s ease-in-out; 
  
}


  
.banner-button {
	background-color: #017BFD;
  color: #ffffff;
  font-size: 18px;
  padding: 15px 24px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  z-index: 12; 
  }
  
  .banner-button:hover {
	background-color: #0056b3;
	color: #fff;
  }
  
  
  .school-info {
	text-align: center;
	margin-top: 590px; 
  }
  
  .school-quote {
	font-size: 32px;
	font-weight: bold;
	text-align: center;
	color: #555;
	font-style: italic;
	margin-bottom: 100px;
	padding: 2rem;

	border-radius: 8px; 
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
	background-color: #f5f5f5;
  }
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Vollkorn:ital,wght@0,600;1,500&display=swap');

:root {
	--type-body: Open Sans, Helvetica, Arial, sans-serif;
	--type-quote: Vollkorn;
	--quote-image-width: 140px;
	--border-rad: 7px;
	--accent-color: hsl(211, 99%, 50%);
	--quote-bg: hsl(0 0% 97%);
}



.quote-container {

	background-color: #F8F8F8;
	animation: fadeIn 2s ease-in-out;
}

.article {
	max-width: 900px;
	margin: auto;
}

.article h1 {
	width: max-content;
	margin: 0 auto 0.1em;
	box-shadow:
		0 2px 0 var(--accent-color),
		0 4px 0 white,
		0 6px 0 var(--accent-color);
	font: 2rem var(--type-quote);
	color: hsl(0 0% 25%);
}

.article p {
	margin: 0;
}


.article blockquote {
	position: relative;
	margin: 40px 0px;
	padding: 1.6em 2.4em .7em calc(1.4em + var(--quote-image-width) - 100px);
	font: italic 1.2rem var(--type-quote);
	background: var(--quote-bg) no-repeat left / var(--quote-image-width);
	border-radius: var(--border-rad);
	border: 2px solid white;
	box-shadow: 2px 2px 4px hsl(0 0% 0% / 20%);
	/* text-indent: 1.6em; */
	text-align: center;
	
}


.article blockquote::before {
	text-align: center;
	content: "";
	pointer-events: none;
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	border-radius: var(--border-rad);
	box-shadow:
		inset -2px -2px 1px hsl(0 0% 100%),
		inset 2px 2px 4px hsl(0 0% 0% / 20%);
}

.article blockquote::after {
	content: "❝";
	position: absolute;
	z-index: 1;
	left: 50%;
	top: -2px;
	transform: translate(-50%, -50%);
	width: 1.3em;
	height: 1.3em;
	background: white;
	box-shadow: 0 4px 5px -1px hsla(0 0% 0% / 20%);
	border-radius: 999px;
	display: grid;
	place-content: center;
	padding-top: .5em;
	color: var(--accent-color);
	font-size: 36px;
	font-style: normal;
	text-indent: 0;
}


  
  .about-us {
	display: flex;
	justify-content: center; 
	align-items: center;
	margin: 2rem 0;
	
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(108, 108, 108, 0.053);
	color: #017BFE;
	background-color: #f8f8f8;
  }
  
  .about-us-image {
	flex: 0 0 40%;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-image img {
	width: 100%;
	height: auto;
  }
  
  .about-us-content {
	flex: 1;
	text-align: center;
	padding: 5rem;
	margin: 0 auto;
  }
  
  .about-us-title {
	font-size: 45px;
	font-weight: bold;
	color: var(--primary);
	margin-bottom: 1rem; 
  }
  
  .about-us-text {
	font-size: 20px;
	color: #333;
	line-height: 2;
	text-align: center;
	font-family: Optima, sans-serif;
	width: 1200px;
	
  }


.mission {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 2rem 0;

	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(108, 108, 108, 0.053);
	color: #017BFE;
	background-color: #f8f8f8;;
  }
  
  .mission-content {
	flex: 1;
	text-align: left; 
	padding: 5rem; 
  }
  
  .mission-title {
	font-size: 45px;
	font-weight: bold;
	color: var(--primary);
	margin-bottom: 1rem; 
  }
  
  .mission-text {
	font-size: 20px;
	color: #333;
	line-height: 2;
	text-align: left; 
  }
  
  .mission-image {
	flex: 0 0 40%;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .mission-image img {
	width: 100%;
	height: auto;
  }
  

.vision {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 2rem 0;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(108, 108, 108, 0.053);
	color: #017BFE;
	background-color: #f8f8f8;;
  }
  
  .vision-content {
	flex: 1;
	text-align: right;
	padding: 5rem; 
  }
  
  .vision-title {
	font-size: 45px;
	font-weight: bold;
	color: var(--primary);
	margin-bottom: 1rem; 
  }
  
  .vision-text {
	font-size: 20px;
	color: #333;
	line-height: 2;
	text-align: left; 
  }
  
  .vision-image {
	flex: 0 0 40%;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .vision-image img {
	width: 100%;
	height: auto;
  }

  #galleries {
	text-align: center;
	margin: 2rem 0;
	font-size: 30px;
  }
  
  #gallery-header {
	font-size: 45px;
	font-weight: bold;
	margin-bottom: 1rem;
	color: #007bff;
  }
  
  .img-list {
	position: absolute;
	left: 45px;

  }
  
  .img-list li {
	
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease-in-out;
  }
  
  .img-list img {
	width: 200px;
	height: 150px;
	object-fit: cover;
  }
  
  .img-list li:hover {
	transform: scale(1.05);
  }
  

  .reserve-button {
	text-align: center;
	margin: 3rem 0;
	border: 1px solid #f5f5f5; 
	padding: 2rem;
	background-color: #0478f6;
  }
  
  .reserve-text {
	font-size: 24px;
	margin-bottom: 1rem;
	color: #FECE00;
  }
  
  .reserve-button-primary {
	background-color: #ffffff;
	color: #8618b2;
	font-size: 24px;
	padding: 12px 40px;
	border: none;
	border-radius: 6px;
	cursor: pointer;
	transition: background-color 0.3s ease-in-out;
  }
  
  .reserve-button-primary:hover {
	background-color: #0056b3;
  }
  
  
  
 
  .map {
	text-align: center;
	
	
  }

  .about-us-content.visible,
.mission-content.visible,
.vision-content.visible,
.img-list.visible,
.reserve-button.visible,
.map.visible {
  animation: slideInFromLeft 1s ease-in-out;
  opacity: 1;
}

.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	margin: 0;
	background:#0f0f0f
  }
  

  .login-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	max-width: 400px;
	padding: 50px;
	border: 1px solid #7a7575;
	border-radius: 50px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	background-color:rgba(240, 240, 240, 0.923); 
  }
  

  .login-box label {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
	color: rgb(0, 0, 0);
	
	
  }
  

  .login-box input {
	width: 100%;
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid #ccc;
	border-radius: 6px;
	font-size: 18px;
	
  }
  
  
  .login-box button {
	width: 60%;
	padding: 16px 24px;
	border: none;
	border-radius: 60px;
	font-size: 18px;
	font-weight: bold;
	color: #fff;
	background-color: #b61e00f3; 
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
  
  .login-box button:hover {
	background-color: #0056b3; 
  }



  .note {
	margin-top: 20px;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	background-color: #f5f5f5;
  }
  
  .note p {
	margin: 0;
	font-size: 20px;
	color: #050505;
	font-family: Impact, fantasy
  }


footer {
	background-color: #333;
	color: #fff;
	padding: 2rem 0;
  }
  
  .footer-container {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  
  .footer-links {
	display: flex;
	gap: 2rem;
  }
  
  .footer-links a {
	color: #fff;
	text-decoration: none;
	font-size: 1rem;
  }
  
  .footer-links a:hover {
	color: #aaa;
  }
  
  .footer-info {
	font-size: 0.9rem;
	text-align: right;
  }
  
  .footer-copyright {
	font-size: 0.8rem;
	text-align: center;
	margin-top: 1rem;
  }
  
  
  @media (max-width: 768px) {
	.footer-container {
	  flex-direction: column;
	  align-items: flex-start;
	}
	.footer-info {
	  text-align: left;
	  margin-top: 1rem;
	}
  }
  
#about footer,
#contact footer,
.admin-container footer,
.login-container footer {
  background-color: #333;
  color: #fff;
  padding: 2rem 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.footer-links a:hover {
  color: #aaa;
}

.footer-info {
  font-size: 0.9rem;
  text-align: right;
}

.footer-copyright {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-info {
    text-align: left;
    margin-top: 1rem;
  }

  #about footer,
  #contact footer,
  .admin-container footer,
  .login-container footer {
    position: relative;
    bottom: initial;
    width: auto; 
  }
}


.timestamp {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
  }

  .timestamp-group {
	margin-bottom: 30px;
  }
  .admin-container {
	padding: 20px;
  }

  .welcome-message {
	font-size: 32px;
	font-weight: bold;
	font-style: italic;
  }
  
  .logout-button {
	padding: 8px 16px;
	background-color: #f44336;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
  }
  
  .logout-button:hover {
	background-color: #d32f2f;
  }
  
  .logout-button:focus {
	outline: none;
  }
  
  .students-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: 600px;
	overflow-y: auto;
	background-color: #fbfbfb;
	
  }
  
  .student-box {
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	width: 200px;
	margin-bottom: 10px;
	background-color: #f0f0f0;
  }
.student-info {
	margin-right: auto;
  }
  
  
  .student-name {
	font-size: 18px;
	font-weight: bold;
	color: #333;
  }
  .download-button {
	margin-left: 20px;
  }
  
  .fa-spinner,
  .fa-file-download {
	font-size: 24px;
	color: #1861e0;
	cursor: pointer;
  }
  
  .fa-spinner.fa-spin {
	animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

  .headerAdmin {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
  }

  #tac {

	background-color: #f2f2f2;
	border: 2px solid #333;
	padding: 10px; 
	border-radius: 8px;  

  }

  /* Mouse */

  .mouse {
	margin: 50px auto;
	width: 100px;
	position: fixed; 
	top: 60%;
	right: 20px; 
  }
  .mouse-icon{
	 width: 25px;
	 height: 45px;
	 border: 2px solid rgb(0, 0, 0);
	 border-radius: 15px;
	 cursor: pointer;
	 position: relative;
	 text-align: center;
  }.mouse-wheel{
	height: 6px;
	margin: 2px auto 0;
	display: block;
	width: 3px;
	background-color: rgb(0, 0, 0);
	border-radius: 50%;
	-webkit-animation: 1.6s ease infinite wheel-up-down;
   -moz-animation: 1.6s ease infinite wheel-up-down;
	animation: 1.6s ease infinite wheel-up-down;
  }
  @-webkit-keyframes wheel-up-down {
	  0% {
		  margin-top: 2px;
		  opacity: 0;
	  }
	  30% {
		  opacity: 1;
	  }
	  100% {
		  margin-top: 20px;
		  opacity: 0;
	  }
  }@-moz-keyframes wheel-up-down {
	  0% {
		  margin-top: 2px;
		  opacity: 0;
	  }
	  30% {
		  opacity: 1;
	  }
	  100% {
		  margin-top: 20px;
		  opacity: 0;
	  }
  }@keyframes wheel-up-down {
	  0% {
		  margin-top: 2px;
		  opacity: 0;
	  }
	  30% {
		  opacity: 1;
	  }
	  100% {
		  margin-top: 20px;
		  opacity: 0;
	  }
  }


  /* Student.css */
/* Add this CSS to your component's CSS file or stylesheets */
.student-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px;
	position: relative;
	background-color: #f3f7fb;

	
  }
  
  .logout-button {
	background-color: #ff0000;
	color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	font-weight: bold;
	transition: background-color 0.2s;
  }
  
  .logout-button:hover {
	background-color: #cc0000;
  }
  
  .big-options {
	display: flex;
	flex-direction: row; /* Horizontal arrangement */
	justify-content: center; /* Center horizontally */
	gap: 20px;
	max-width: 800px;
	width: 100%;
	margin: 0 auto; /* Center using margins */
	margin-top: 50px;
  }
  
  .left-options,
  .right-options {
	display: flex;
	flex-direction: column;
	flex: 1;
  }
  
  /* Individual styles for the options */
  .big-option {
	text-align: center;
	background-color: #f0f0f0;
	border: 1px solid #ddd;
	padding: 50px;
	border-radius: 10px;
	transition: transform 0.2s, box-shadow 0.2s;
	text-decoration: none;
	color: #333;
	margin-top: 30px;
	cursor: pointer;
	
  }
  
  .big-option:hover {
	transform: scale(1.05);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .big-option-content {
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  .big-option h2 {
	font-size: 24px;
	margin: 0;
  }
  
  .big-option p {
	font-size: 16px;
	margin-top: 10px;
	color: #666;
  }
  

/* Styles for the Teacher component */
/* Teacher Grade .css */

.teacher-grade {
	display: flex;
	background-color: #f0f0f0;
	padding: 150px;
	min-height: 100vh;
	justify-content: center; /* Center the content horizontally */
	align-items: flex-start;
}

.teacher-grade #searchh2{

	font-size: 36px;
	
}

#searchh2{
	margin-bottom: 20px;

}
  
.teacher-grade .left-content {
	flex: 1;
	padding-right: 350px; /* Adjust the padding to create space */
	padding-left: 30px; /* Add some padding to the left */
  }
  
.teacher-grade .right-content {
	flex: 1;

  }
  
  .teacher-grade .center-content {
	text-align: center;
	max-width: 600px;
	margin: 0 auto;
  }
  
  .teacher-grade .header {
	font-size: 36px;
	margin-bottom: 20px;
	color: #333;
  }
  
  .teacher-grade .logout-button {
	background-color: #0066ff;
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	margin-bottom: 20px;
  }
  
  .teacher-grade .logout-button:hover {
	background-color: #c0392b;
  }
  
  .teacher-grade .search-container {
	margin-bottom: 20px;
  }
  
  .teacher-grade .search-input {
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	width: 300px;
	font-size: 16px;
  }
  
  .teacher-grade .search-button {
	padding: 10px 20px;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	cursor: pointer;
	margin-left: 10px;
  }
  
  .teacher-grade .search-button:hover {
	background-color: #0056b3;
  }
  
  .teacher-grade .search-results {
	text-align: left;
  }
  
  .teacher-grade .search-results h2 {
	font-size: 24px;
	margin-bottom: 10px;
  }
  
  .teacher-grade ul {
	list-style: none;
	padding: 0;
  }
  
  .teacher-grade li {
	font-size: 18px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 5px;
	background-color: #fff;
	transition: background-color 0.3s ease;
  }
  
  .teacher-grade li:hover {
	background-color: #f5f5f5;
  }


  /* Teacher container */
  .teacher-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px;
	background-color: #f0f0f0;
  }

  .teacher-header {
	color: #333;
	font-size: 50px;
	
  }

  .teacher-container .big-options {
	display: flex;
	flex-direction: row; /* Horizontal arrangement */
	justify-content: center; /* Center horizontally */
	gap: 20px;
	max-width: 1000px;
	width: 100%;
	margin: 0 auto; /* Center using margins */
	margin-top: 50px;
  }

  .teacher-container .big-option {
	text-align: center;
	background-color: #f0f0f0;
	border: 1px solid #ddd;
	padding: 50px;
	border-radius: 10px;
	transition: transform 0.2s, box-shadow 0.2s;
	text-decoration: none;
	color: #333;
	margin-top: 30px;
	cursor: pointer;
	
  }

   
  .teacher-container .left-options,
  .right-options {
	display: flex;
	flex-direction: column;
	flex: 1;
  }
  

/* studentinfo */
/* Define some common styles */
/* Contstudent-info2ainer for the entire student information section */
.student-info2 {
	padding: 20px;
	font-family: Arial, sans-serif;
	background-color: #f7f7f7;
	border: 1px solid #ddd;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	margin: 20px;
  }
  
  /* Section heading style */
  .student-info2 h2 {
	color: #333;
	font-size: 24px;
	margin-bottom: 15px;
  }
  #student-profile {
	font-size: 20px;
  }
  /* Buttons container */
  .button-container {
	display: flex;
	justify-content: flex-end;
	
  }
  
  /* Edit and Save buttons style */
  .edit-button,
  .save-button,
  .discard-button {
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-weight: 600;
	margin-right: 10px;
	transition: background-color 0.3s ease;
  }
  
  .edit-button {
	background-color: #007bff;
	color: #fff;
  }
  
  .edit-button:hover {
	background-color: #0056b3;
  }
  
  .save-button {
	background-color: #28a745;
	color: #fff;
  }
  
  .save-button:hover {
	background-color: #218838;
  }
  
  .discard-button {
	background-color: #dc3545;
	color: #fff;
  }
  
  .discard-button:hover {
	background-color: #c82333;
  }
  
  /* Table style */
  .student-info2 table {
	width: 100%;
	border-collapse: collapse;
	margin: 20px 0;
  }
  
  /* Table header and cell style */
  .student-info2 th, td {
	padding: 10px;
	text-align: left;
	border-bottom: 1px solid #ddd;
  }
  
  .student-info2 th {
	background-color: #f2f2f2;
	font-weight: 600;
  }
  
  /* Input fields style */
  .student-info2
  input[type="text"],
  input[type="number"],
  input[type="date"] {
	padding: 5px;
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 4px;
  }
  
  /* Subject and Activity Section */
  .subject-activity-section {
	margin-top: 20px;
  }
  
  /* Add Subject button style */
  .add-subject-button {
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-weight: 600;
	transition: background-color 0.3s ease;
  }
  
  .add-subject-button:hover {
	background-color: #0056b3;
  }
  
  /* List style */
  .student-info2 ul {
	list-style-type: none;
	padding: 0;
  }
  
  /* List item style */
  .student-info2 li {
	margin-bottom: 10px;
  }
  
  /* Responsive layout adjustments (adjust as needed) */
  @media (max-width: 768px) {
	.button-container {
	  flex-direction: column;
	  align-items: flex-end;
	}
  
	.edit-button,
	.save-button,
	.discard-button {
	  width: 100%;
	  margin: 10px 0;
	}
  
	th, td {
	  padding: 5px;
	}
  }
  
 /* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
	cursor: pointer;
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
	width: 200px;
	background-color: #333;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 10px;
	position: absolute;
	z-index: 1;
	top: -85px;
	left: -40px;
	transform: translateX(-50%);
	opacity: 1;
  }
  
  /* Close button inside the tooltip */
  .tooltip .tooltiptext button {
	background-color: transparent;
	color: #fff;
	border: none;
	cursor: pointer;
	font-size: 14px;
	margin-top: 10px;
  }
  
  /* Show the tooltip */
  .tooltip .tooltiptext {
	visibility: visible;
  }
  
  /* Hide the tooltip */
  .tooltip .tooltiptext.hidden {
	visibility: hidden;
	opacity: 0;
  }

  /* Viewgrade */
  #viewgrade {
	font-family: "Helvetica Neue", Arial, sans-serif;
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	background-color: #f9f9f9;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	transition: background-color 0.3s ease;
  }

#viewgrade h2 {
	font-size: 28px;
	margin-bottom: 20px;
	color: #333;
	text-align: center;
  }

#viewgrade h3 {
	font-size: 24px;
	margin-top: 20px;
	color: #555;
  }

#viewgrade table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
	background-color: #fff;
	border: 1px solid #ddd;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	transition: background-color 0.3s ease;
  }

#viewgrade table, th, td {
  border: 1px solid #ddd;
}
  

#viewgrade th, td {
	padding: 12px;
	text-align: left;
  }

  #viewgrade thead {
	background-color: #000000;
	color: #ffffff;
  }

#viewgrade th {
  font-weight: bold;
}


#viewgrade tr:nth-child(even) {
	background-color: #ffffff;
  }
#viewgrade tr:hover {
	background-color: #676565;
	transition: background-color 0.3s ease;
  }

#gobackcontainer {
	text-align: right;
	margin-top: 20px;
}

#goback :hover {
	background-color: #0056b3; 
}
#goback {
	background-color: #007bff; 
	color: #fff; 
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }


/* student-message-container */

.student-message-container {
	max-width: 600px; 
	margin: 0 auto;
	margin-top: 10px;
	border: 1px solid #ccc;
	border-radius: 50px;
	padding: 30px;
	background-color: #f8f8f8;; 
	box-shadow: 0 0 10px rgba(6, 120, 197, 0.64);
	
	
  }
  

  
 
.student-message-container .message-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: #f8f8f8;
	padding: 20px; 
	height: 650px; 
	overflow-y: auto; 
	
  }
  
  
  
.student-message-container .message-item {
	padding: 10px;
	border-radius: 10px;
	word-wrap: break-word;
	background-color: #e8e6e6; 
	color: #000000;
	margin: 10px 0;
	max-width: 30%;
  }
  
  
  
.student-message-container .message-sender {
	font-weight: bold;
	max-width: 30%;
	
  }
  
  
  
.student-message-container .message-item.receiver {
	background-color: #803EE4; 
	align-self: flex-end; 
	color: #eeecec;
	max-width: 50%;
  }

  
  
  
.student-message-container .message-input {
	display: flex;
	gap: 10px;
	margin-top: 10px;
  }
  
  

  .student-message-container .message-input input {
	flex: 1;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
  }
  
  
  
.student-message-container .message-input button {
	padding: 10px 20px;
	background-color: #007BFF; 
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
  }
  
  
  
.student-message-container .message-input button:hover {
	background-color: #0056b3; 
  }

/* Styles for the Teacher Message Container */
/* Styles for the Teacher Message Container */
.teacher-message-container {
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 10px;
	background-color: #f5f5f5;
	display: flex;
	border: 1px solid #ccc;
	border-radius: 50px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	position: relative; /* Add relative positioning to the container */
  }
  
  /* Styles for the Student List */
  .student-list {
	width: 150px; /* Set a fixed width for the student list */
	padding: 20px;
	background-color: #007BFF;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	color: #fff;
	text-align: center;
	display: flex;
	flex-direction: column;
	/* align-items: flex-start; Adjusted to align items to the left */
  }
  
  .student-list h2 {
	font-size: 20px;
	margin-bottom: 10px;
	text-align: center;
	color: #fff
  }
  
  .student-list ul {
	list-style: none;
	padding: 0;
	
  }
  
  .student-list li {
	padding: 10px;
	font-size: 18px;
	cursor: pointer;
	transition: background-color 0.2s;
	background-color: #1100ff;
	margin: 10px;
  }

  
  .student-list .selected {
	/* font-weight: bold; */
	background-color: #ff0000;
  }
  
  /* Styles for the Message List */
  .teacher-message-container .message-list {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 50px;
	background-color: #fff;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 1px solid #ccc;
	overflow-y: auto;
	height: 700px;
  }
  
  .teacher-message-container .message-list .message-item {
	padding: 10px;
	background-color: #e8e6e6;
	border-radius: 10px;
	word-wrap: break-word;
	margin: 10px 0;
	font-size: 16px;
	
  }

  
  .teacher-message-container .message-list .message-item.sent {
	align-self: flex-end;
	background-color: #007BFF;
	color: #fff;
	border-radius: 30px;
	max-width: 30%;
	
  }
  
  .teacher-message-container .message-list .message-item.received {
	background-color: #dfe2e6;
	max-width: 30%;
	border-radius: 30px;
  }
  
  /* Styles for Input and Send Button */
  .teacher-message-container .message-input {
	display: flex;
	align-items: center;
	padding: 10px;
	background-color: #fff;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	position: absolute; /* Position at the bottom */
	bottom: 0; /* Stick to the bottom */
	left: 0;
	width: 100%;
  }
  
  .teacher-message-container .message-input input {
	flex: 1;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	/* margin-right: 10px; */
  }
  
  .teacher-message-container .message-input button {
	padding: 10px 20px;
	background-color: #007BFF;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	margin-right: 5px;
  }
  


/* InputAttendan/* InputAttend/* InputAttendance.css */

.input-attendance {
	margin: 20px;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #f9f9f9;
  }

  .input-attendance .search-input{
	width: 50%;
	font-size: 20px;
	margin-bottom: 10px;

  }
  
  .input-attendance li {

	font-size: 18px;
	margin-bottom: 20px;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 5px;
	background-color: #fff;
	transition: background-color 0.3s ease;
	width: 30%;
  }
  .input-attendance-header {
	font-size: 24px;
	margin-bottom: 20px;
  }
  
  .input-attendance-add-button {
	background-color: #007BFF;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
  }
  
  .input-attendance-add-button:hover {
	background-color: #0056b3;
  }
  
  .modal-title {
	font-size: 20px;
	margin-bottom: 20px;
  }
  
  .modal-form label {
	display: block;
	margin-bottom: 10px;
  }
  
  .modal-form input, .modal-form select {
	width: 99%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 15px;
	font-size: 16px;
  }
  
  .modal-form button {
	background-color: #007BFF;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
  }
  
  .modal-form button:hover {
	background-color: #0056b3;
  }
  
  .input-attendance-record-header {
	font-size: 20px;
	margin-top: 20px;
	margin-bottom: 10px;
  }
  
  table.input-attendance-table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
  }
  
  table.input-attendance-table th, table.input-attendance-table td {
	border: 1px solid #ccc;
	padding: 10px;
	text-align: center;
  }
  
  table.input-attendance-table th {
	background-color: #007BFF;
	color: white;
  }
  
  table.input-attendance-table tr:nth-child(even) {
	background-color: #f2f2f2;
  }
  
  table.input-attendance-table tr:nth-child(odd) {
	background-color: #fff;
  }
  
  table.input-attendance-table button {
	background-color: #dc3545;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 5px 10px;
	cursor: pointer;
  }
  
  table.input-attendance-table button:hover {
	background-color: #a02b3e;
  }
  
  /* Modal.css */
  
  .modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
  }
  
  .modal-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	width: 80%;
	max-width: 500px;
	position: relative;
	text-align: left;
  }
  
  .modal-close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: red;
	padding: 10px;
	border: none;
	font-size: 16px;
	cursor: pointer;
	color: #ffffff;
  }
  
  .current-date-time {
	font-size: 20px; 
	font-weight: 600; 
	color: #333; 
	margin: 20px 0; 
	padding: 10px; 
	border: 1px solid #ccc; 
	background-color: #f7f7f7; 
	border-radius: 4px; 
	text-align: center; 
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 

  }
  
/* student-attendance.css */
.student-attendance-container {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	background-color: #f5f5f5;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Header style */
  .student-attendance-header {
	font-size: 24px;
	margin-bottom: 20px;
  }
  
  /* Attendance section style */
  .attendance-section {
	background-color: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	margin-bottom: 20px;
	padding: 20px;
  }
  
  /* Title style */
  .attendance-section h2 {
	font-size: 20px;
	margin-bottom: 10px;
  }
  
  /* Table style */
  .attendance-table {
	width: 100%;
	border-collapse: collapse;
  }
  
  /* Table header style */
  .attendance-table th {
	background-color: #007acc;
	color: #fff;
	padding: 10px;
	text-align: left;
  }
  
  /* Table header cells style */
  .attendance-table th:first-child {
	border-top-left-radius: 5px;
  }
  
  .attendance-table th:last-child {
	border-top-right-radius: 5px;
  }
  
  /* Table body cells style */
  .attendance-table td {
	padding: 10px;
	border: 1px solid #e0e0e0;
  }
  
  /* Alternating row colors */
  .attendance-table tbody tr:nth-child(even) {
	background-color: #f7f7f7;
  }